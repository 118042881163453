import { createStore } from 'redux';
import navigation from './containers/_nav';

const initialState = {
	sidebarShow: 'responsive',
	auth: {
		isLoggedIn: false,
		secret_key: null,
		access_token: null,
		refresh_token: null,
		expire_time: null,
		updated_at: null,
		_id: null,
		username: null,
	},
	company: {
		_id: "60697ebb185bd5f8e889b039",
		name: "",
		logo: "",
		office_address: "",
		lat: "",
		long: "",
		contact_number: "",
		contact_email: "",
		support_email: "",
		sales_email: "",
		facebook_link: "",
		instagram_link: "",
		youtube_link: "",
		twitter_link: "",
		pinterest_link: "",
		linkedin_link: "",
		google_plus_link: "",
	},
	settings: {
		_id: "60697fe4185bd5f8e889b03b",
		buying_fees: 0,
		selling_fees: 0,
		general_tax: 0,
		penalty: 0,
		shipping_fees: 0,
	},
	user: {
		_id: null,
		username: null,
		email_id: null,
		first_name: null,
		last_name: null,
		surname: null,
		mobile_no: null,
		avatar: null,
		user_role_id: null,
		user_role: null,
		user_group: null,
		user_group_id: null,
		type: null,
		type_id: null,
		parent_id: null,
		web_token: null,
	},
	rights: [],
	nav: [],
	page: {
		page_no: null,
		per_page: null,
		module: null,
	},
	mainUrl: process.env.REACT_APP_HOST_URL,
}

let page = JSON.parse(window.localStorage.getItem('page'));
let user = JSON.parse(window.localStorage.getItem('news_feed_admin_user'));
let auth = JSON.parse(window.localStorage.getItem('news_feed_admin_auth'));
let rights = JSON.parse(window.localStorage.getItem('news_feed_admin_rights'));
let navSide = JSON.parse(window.localStorage.getItem('news_feed_admin_nav'));
let company = JSON.parse(window.localStorage.getItem('news_feed_admin_company'));
let settings = JSON.parse(window.localStorage.getItem('news_feed_admin_settings'));
if (page) {
	initialState.page = page
}
if (user) {
	initialState.user = user
}
if (auth) {
	initialState.auth = auth
}
if (rights) {
	initialState.rights = rights
}
if (company) {
	initialState.company = company
}
if (settings) {
	initialState.settings = settings
}
if (navSide) {
	initialState.nav = navSide
} else {
	initialState.nav = navigation
}
const changeState = (state = initialState, { type, ...rest }) => {
	switch (type) {
		case 'getCount':
			return state
		case 'getPg':
			const pg = window.localStorage.getItem('page');
			let pages = null;
			if (pg) {
				pages = JSON.parse(pg);
			}
			return { ...state, page: pages };
		case 'getRight':
			const rts = window.localStorage.getItem('news_feed_admin_rights')
			let right = null;
			if (rts) {
				right = JSON.parse(rts);
			}
			return { ...state, rights: right };
		case 'getNav':
			const nv = window.localStorage.getItem('news_feed_admin_nav')
			let nvs = null;
			if (nv) {
				nvs = JSON.parse(nvs);
			} else {
				nvs = navigation;
			}
			return { ...state, nav: nvs };
		case 'setPage':
			window.localStorage.setItem('page', JSON.stringify({
				page_no: rest.payload.page_no,
				per_page: rest.payload.per_page,
				module: rest.payload.module,
			}))
			return {
				...state,
				page_no: rest.payload.page_no,
				per_page: rest.payload.per_page,
				module: rest.payload.module,
			}
		case 'setUser':
			window.localStorage.setItem('news_feed_admin_user', JSON.stringify(rest.user))
			return {
				...state,
				user: rest.user
			}
		case 'setCompany':
			window.localStorage.setItem('news_feed_admin_company', JSON.stringify(rest.company))
			return {
				...state,
				company: rest.company
			}
		case 'setSettings':
			window.localStorage.setItem('news_feed_admin_settings', JSON.stringify(rest.settings))
			return {
				...state,
				settings: rest.settings
			}
		case 'setNav':
			window.localStorage.setItem('news_feed_admin_nav', JSON.stringify(rest.nav))
			return {
				...state,
				nav: rest.nav
			}
		case 'setAuth':
			window.localStorage.setItem('news_feed_admin_auth', JSON.stringify(rest.auth))
			return {
				...state,
				auth: rest.auth
			}
		case 'setRights':
			window.localStorage.setItem('news_feed_admin_rights', JSON.stringify(rest.rights))
			return {
				...state,
				rights: rest.rights
			}
		case 'logout':
			window.localStorage.removeItem('news_feed_admin_auth')
			window.localStorage.removeItem('news_feed_admin_user')
			window.localStorage.removeItem('news_feed_admin_company')
			window.localStorage.removeItem('news_feed_admin_settings')
			window.localStorage.removeItem('news_feed_admin_rights')
			window.localStorage.setItem('news_feed_admin_nav', JSON.stringify(navigation))
			window.localStorage.removeItem('page')
			const dummyAuth = {
				isLoggedIn: false,
				secret_key: null,
				access_token: null,
				refresh_token: null,
				expire_time: null,
				updated_at: null,
				_id: null,
				username: null,
			};
			const dummyCompany = {
				_id: "60697ebb185bd5f8e889b039",
				name: "",
				logo: "",
				office_address: "",
				lat: "",
				long: "",
				contact_number: "",
				contact_email: "",
				support_email: "",
				sales_email: "",
				facebook_link: "",
				instagram_link: "",
				youtube_link: "",
				twitter_link: "",
				pinterest_link: "",
				linkedin_link: "",
				google_plus_link: "",
			};
			const dummySettings = {
				_id: "60697fe4185bd5f8e889b03b",
				buying_fees: 0,
				selling_fees: 0,
				general_tax: 0,
				penalty: 0,
				shipping_fees: 0,
			};
			const dummyUser = {
				_id: null,
				username: null,
				email_id: null,
				first_name: null,
				last_name: null,
				surname: null,
				mobile_no: null,
				avatar: null,
				user_role_id: null,
				user_role: null,
				user_group: null,
				user_group_id: null,
				type: null,
				type_id: null,
				parent_id: null,
				web_token: null,
			};
			const dummyPage = {
				page_no: 1,
				per_page: 10,
				module: "",
			}
			return {
				...state,
				auth: dummyAuth,
				user: dummyUser,
				company: dummyCompany,
				settings: dummySettings,
				rights: [],
				nav: navigation,
				page: dummyPage,
			}
		case 'set':
			return { ...state, ...rest }
		default:
			return state
	}
}

const store = createStore(changeState)
export default store
